/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
// import { AspectRatio } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the generic_iframe api component
 * @param {Object} sectionContent - sectionContent from API
 * @returns {ReactElement} Generic IFrame component
 */

const GenericFrame = ({ sectionContent, section }) => {
  const iframeRef = useRef();
  const router = useRouter();
  // const url = router.asPath;
  // consoleLog('apptype', router?.query?.apptype, router?.query?.apptype == '21');
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (router?.query?.apptype == '21') {
      setHeight('1300px');
    }
  }, [router?.query?.apptype]);
  // consoleLog('heoght', router?.query?.apptype, height);

  // const handleFrameLoad = () => {
  //   setHeight(
  //     iframeRef?.current?.contentWindow?.document?.body?.scrollHeight + 'px'
  //   );
  // };
  if (sectionContent.height == '2700')
  {
      sectionContent.height = '2400'
  }
  return (
    <MidContainer>
      <Box id={section.sectionId}>
        <Box
          bgColor="#fcf9f2"
          // padding={'1em'}
          borderRadius="7px"
          // boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
          ref={iframeRef}
          as="iframe"
          //scrolling="no"
          frameBorder="0"
          src={`${sectionContent?.url}${router?.query?.apptype ? '?appType=' + router?.query?.apptype : ''}`}
          width="100%"
          //height={{ base: '2430px', sm: '230px', md: '230px', lg: '2430px' }}
          height={`${height ? (height || '700px') : (sectionContent?.height?.indexOf('px') > -1 ? sectionContent.height : sectionContent.height + 'px')} !important`}
          overflowY={'scroll'}
          id="register"
          m="30px auto"
        ></Box>
      </Box>
    </MidContainer>
  );
};

export default GenericFrame;
